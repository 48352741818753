import * as Msdyn365 from '@msdyn365-commerce/core';

export class ChangeCustomerReferenceInput implements Msdyn365.IActionInput {
    public scheduleNumber: string;
    public customerReference: string;

    constructor(scheduleNumber: string, customerReference: string) {
        this.scheduleNumber = scheduleNumber;
        this.customerReference = customerReference;
    }

    public getCacheKey = () => `ChangeCustomerReference + ${this.customerReference} ${new Date()}`;
    public getCacheObjectType = () => `ChangeCustomerReference ${new Date()}`;
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

export interface IChangeCustomerReferenceData {
    response: IChangeCustomerReferenceResult;
}

export interface IChangeCustomerReferenceResult {
    value: boolean;
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new ChangeCustomerReferenceInput('', '');
};

async function action(input: ChangeCustomerReferenceInput, ctx: Msdyn365.IActionContext): Promise<IChangeCustomerReferenceData> {
    let result: IChangeCustomerReferenceResult = { value: false };
    await Msdyn365.sendRequest(
        `${ctx.requestContext.apiSettings.baseUrl}Commerce/TecChangeCustomerRef`,
        'post',
        {
            changeCustomerRefModel: {
                SubBillBillingScheduleNumber: input.scheduleNumber,
                CustomerReference: input.customerReference
            }
        },
        { headers: { OUN: ctx.requestContext.apiSettings.oun, Authorization: `id_token ${ctx.requestContext.user.token}` } }
    ).then(res => {
        result = {
            value: (res?.data as IChangeCustomerReferenceResult)?.value
        };
    });

    return { response: result };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IChangeCustomerReferenceData>>action,
    id: 'TecChangeCustomerRef',
    input: createInput
});
