import { createDataActionHook, IActionInput, IActionContext } from '@msdyn365-commerce/core';
import { ProductPrice } from '@msdyn365-commerce/retail-proxy';
import getPriceAction, { GetGraduatedPriceInput } from './get-graduated-price.action';

const afterGetPrices = async (inputs: IActionInput | IActionInput[], results: IActionInput | IActionInput[], context: IActionContext) => {
    try {
        // Can it be an array though?
        const price = (results && Array.isArray(results) ? results[0] : results) as ProductPrice;
        // TODO: use correct "React way" instead of hacky/ugly quick solution
        const quantity = (document.querySelector('input.quantity-input') as HTMLInputElement)?.value;
        if (context.requestContext.user.isAuthenticated && quantity && price && price.CatalogId && price.ProductId) {
            try {
                const actionInput = new GetGraduatedPriceInput(+quantity, price.ProductId, price.CatalogId);
                const graduatedPrice = await getPriceAction(actionInput, context);
                // All these prices have to be set to graduated price to prevent display of strike-through-prices
                (results as ProductPrice).AdjustedPrice = +graduatedPrice.ProductPrice;
                (results as ProductPrice).CustomerContextualPrice = +graduatedPrice.ProductPrice;
                (results as ProductPrice).TradeAgreementPrice = +graduatedPrice.ProductPrice;
            } catch (e) {
                console.error('Could not retrieve graduated price.');
            }
        }
    } catch (ex) {
        console.error('Error with converting result to price or reading quantity from input.');
    }
    return results;
};

createDataActionHook({
    actionId: '@msdyn365-commerce-modules/retail-actions/get-price-for-selected-variant',
    postHook: afterGetPrices
});
